import appConfig from "appConfig";
import Repository from "@/axios.config.js";
const baseDomain = appConfig.BASE_URI;
const baseURL = `${baseDomain}`;
const resource = "/home";

export default {
    getTokenUrl() {
        return Repository.get(`${baseURL}${resource}/getToken`);
    },
    getOrderDetailsUrl(orderNumber) {
        return `${baseURL}${resource}/getOrderByNumber?orderNumber=${orderNumber}`;
    },
    addPhoneNumber(data, token) {
        return Repository.post(`${baseURL}${resource}/addPhoneNumber`, data, {
            headers: {
                "X-CSRF-TOKEN-HEADERNAME": token,
            },
        });
    },
};