<template>
  <div class="home-container">
    <search />
    <search-footer />
  </div>
</template>

<script>
import Search from "@/components/Search.vue";
import SearchFooter from "@/components/SearchFooter.vue";

import { mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    Search,
    SearchFooter,
  },
  methods: {
    ...mapActions(["setOrderDetails", "setToken"]),
  },
  mounted() {
    this.setToken();
  },
};
</script>
