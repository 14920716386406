<template>
    <footer class="home-container-footer">
        <span>
            Jesteś dealerem?
            <a href="https://infopanel.cloud/" target="_blank">Dowiedz się więcej!</a>
        </span>
    </footer>
</template>

<script>
export default {
  name: "SearchFooter",
};
</script>