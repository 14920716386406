import Vue from "vue";
import Vuex from "vuex";
import apiRepository from "../apiRepositories/orderApiRepository";
import Axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        orderDetails: {
            Id: null,
            OrderNumber: "",
            StartDate: null,
            EndDate: null,
            DurationInMinutes: 0,
            Status: "",
            IsFinishedStatus: false,
            CanSendSMS: true,
        },
        token: null,
    },
    getters: {
        getOrderNumber: (state) => {
            return state.orderDetails.OrderNumber;
        },
        getStatus: (state) => {
            return state.orderDetails.Status;
        },
        getIsFinishedStatus: (state) => {
            return state.orderDetails.IsFinishedStatus;
        },
        getCanSendSMS: (state) => {
            return state.orderDetails.CanSendSMS;
        },
    },
    mutations: {
        setToken: (state, payload) => {
            state.token = payload;
        },
        setOrderDetails: (state, payload) => {
            if (payload != null) {
                (state.orderDetails.Id = payload.id),
                    (state.orderDetails.OrderNumber =
                        payload.orderNumber),
                    (state.orderDetails.StartDate = payload.startDate),
                    (state.orderDetails.EndDate = payload.endDate),
                    (state.orderDetails.DurationInMinutes = payload.durationInMinutes),
                    (state.orderDetails.Status = payload.status),
                    (state.orderDetails.IsFinishedStatus = payload.isFinishedStatus),
                    (state.orderDetails.CanSendSMS = payload.canSendSMS);
            } else {
                state.orderDetails = null;
            }
        },
    },
    actions: {
        setToken: ({ commit }) => {
            apiRepository
                .getTokenUrl()
                .then((response) => {
                    commit("setToken", response.data.token);
                })
                .catch((err) => console.log(err));
        },
        setOrderDetails: ({ commit }, orderNumber) => {
            return new Promise((resolve) => {
                let url = apiRepository.getOrderDetailsUrl(orderNumber);
                Axios.get(url)
                    .then((response) => {
                        commit("setOrderDetails", response.data);
                        resolve(response.data);
                    })
                    .catch((err) => console.log(err));
            });
        },
        addPhoneNumber: ({ state }, phoneNumber) => {
            let payload = {
                orderId: state.orderDetails.Id,
                phoneNumber: phoneNumber,
            };
            return new Promise((resolve) => {
                apiRepository
                    .addPhoneNumber(payload, state.token)
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => console.log(err));
            });
        },
    },
});