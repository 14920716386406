<template>
    <div class="home-container-search">
        <h2>Witaj kierowco!</h2>
        <h4>Sprawdź status naprawy pojazdu.</h4>
        <dx-validation-group :ref="`validationGroup`">
            <div class="search-box">
                <dx-text-box height="42px"
                             placeholder="Numer rejestarcyjny..."
                             width="100%"
                             v-model="orderNumber"
                             @enterKey="getOrderDetailsHandler">
                    <dx-validator>
                        <dx-required-rule message="Pole wymagane" />
                    </dx-validator>
                </dx-text-box>
                <div class="search-box-btn" @click="getOrderDetailsHandler">
                    <i class="far fa-search"></i>
                </div>
            </div>
        </dx-validation-group>
        <div class="search-infobox">
            <p>Twoje auto pod kontrolą.</p>
        </div>
        <dx-popup :drag-enabled="false"
                  :close-on-outside-click="true"
                  height="auto"
                  class="custom-popup"
                  :show-title="false"
                  width="80%"
                  :visible="isPopupVisible"
                  @hiding="isPopupVisible = false">
            <div class="popup-container" @click="isPopupVisible = false">
                <i class="close-icon far fa-times"></i>
                <i class="icon danger far fa-exclamation-circle"></i>
                <h4 class="message">Przykro nam, nie znaleźliśmy tego pojazdu.</h4>
            </div>
        </dx-popup>
        <dx-load-panel :visible="loadPanelConfig.visible"
                       :show-indicator="true"
                       :show-pane="true"
                       message="Pobieranie danych"
                       :shading="true"
                       shading-color="rgba(0,0,0,0.4)" />
    </div>
</template>

<script>
    import { DxPopup } from "devextreme-vue/popup";
    import DxTextBox from "devextreme-vue/text-box";
    import DxValidationGroup from "devextreme-vue/validation-group";
    import { DxRequiredRule, DxValidator } from "devextreme-vue/validator";
    import { DxLoadPanel } from "devextreme-vue/load-panel";

    import { mapActions } from "vuex";

    export default {
        name: "Search",
        components: {
            DxTextBox,
            DxValidator,
            DxRequiredRule,
            DxValidationGroup,
            DxPopup,
            DxLoadPanel,
        },
        data() {
            return {
                orderNumber: null,
                isPopupVisible: false,
                loadPanelConfig: {
                    visible: false,
                },
            };
        },
        methods: {
            ...mapActions(["setOrderDetails"]),
            getOrderDetailsHandler: function () {
                let validateResult = this.$refs["validationGroup"].instance.validate();
                if (validateResult.isValid) {
                    let globalThis = this;
                    this.loadPanelConfig.visible = true;
                    this.setOrderDetails(this.orderNumber).then((data) => {
                        globalThis.loadPanelConfig.visible = false;
                        if (data) {
                            globalThis.$router.push({
                                name: "Details",
                                params: { orderNumber: data.orderNumber },
                            });
                        } else {
                            globalThis.isPopupVisible = true;
                        }
                    });
                }
            },
        },
    };
</script>