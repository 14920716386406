<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "./assets/styles/main.scss";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "@fortawesome/fontawesome-pro/css/fontawesome.min.css";
import "@fortawesome/fontawesome-pro/css/regular.min.css";

export default {};
</script>
