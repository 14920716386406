/* eslint-disable */
import axios from "axios";
import appConfig from "appConfig";
const baseDomain = appConfig.BASE_URI;

const baseURL = `${baseDomain}/api`;
const tokenItemName = appConfig.TOKEN_ITEM_NAME;

const token = localStorage.getItem(tokenItemName);
var auth = {
  baseURL,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

const instance = axios.create(auth);

export default instance;
